/* NotFound.css */

.not-found-container {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
}

.not-found-title {
  font-size: 2.5rem;
  color: #333;
}

.not-found-text {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.not-found-image {
  width: 30%;
  height: 30%;
  margin-bottom: 30px;
  mix-blend-mode: darken;
}

.not-found-buttons {
  display: flex;
  justify-content: center;
}

.not-found-link {
  text-decoration: none;
}

.not-found-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #0056b3;
}
